import React from "react";
import ReactDOM from "react-dom";
import "@hide-on-bush-x/masa-ui/dist/masa-ui.cjs.development.css"
import "@hide-on-bush-x/masa-ui/dist/tailwind.css";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ContractKitProvider, NetworkNames } from '@celo-tools/use-contractkit'


ReactDOM.render(
  <React.StrictMode>
    <ContractKitProvider
      network={{
        name: NetworkNames.Alfajores,
        rpcUrl: "https://alfajores-forno.celo-testnet.org",
        graphQl: "https://alfajores-blockscout.celo-testnet.org/graphiql",
        explorer: "https://alfajores-blockscout.celo-testnet.org",
        chainId: 44787,
      }}
      dapp={{
        name: "My awesome dApp",
        description: "My awesome description",
        url: "https://example.com",
        icon: "https://example.com/icon.png",
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContractKitProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
