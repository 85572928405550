import { Card } from "@matintosh/masa-ui";
import { kFormatter } from "../../helpers/k-formater";
import { useCorn } from "../../hooks/useCorn";
import { useStake } from "../../hooks/useStake";
import "./styles.css";

export function MasaStats() {
  const { cornBalance } = useCorn();
  const { stakedCorn, stakingRewards } = useStake();

  return (
    <Card className='masa-stats'>
      <div>
        <h3 className={"masa-stats-subtitle"}>🌽 Masa Protocol Stats </h3>

        <p className={"masa-stats-value"}>
          Network: <span>Testnet</span>
        </p>
        <p className={"masa-stats-value"}>
          Total Rewards Pool: <span>Coming soon</span>
        </p>
        <p className={"masa-stats-value"}>
          Total Pooled Tokens: <span>Coming soon</span>
        </p>
        <p className={"masa-stats-value"}>
          Rewards/Epoch: <span>Coming soon</span>
        </p>
      </div>
      <div>
        <h3 className={"masa-stats-subtitle"}>📈 Your Stats</h3>
        <p className={"masa-stats-value"}>
          Wallet Balance: <span>{kFormatter(cornBalance)} tCORN</span>
        </p>
        <p className={"masa-stats-value"}>
          LP Token Balance: <span>Coming soon</span>
        </p>
        <p className={"masa-stats-value"}>
          Total Staked Tokens: <span>{kFormatter(stakedCorn)} tCORN</span>
        </p>
        <p className={"masa-stats-value"}>
          Rewards Earned: <span>{kFormatter(stakingRewards)}tCORN</span>
        </p>
      </div>
      <div>
        <h3 className={"masa-stats-subtitle"}>🏗️ Resources</h3>
        <a href='https://developers.masa.finance'>
          <u>
            <p className={"masa-stats-value"}>Developer Docs</p>
          </u>
        </a>
        <a href='https://developers.masa.finance/docs/developer-api'>
          <u>
            <p className={"masa-stats-value"}>API Reference</p>
          </u>
        </a>
        <a href='https://discord.com/invite/NJKEFTmcaB'>
          <u>
            <p className={"masa-stats-value"}>Discord</p>
          </u>
        </a>
      </div>
    </Card>
  );
}
