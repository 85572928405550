import "@celo-tools/use-contractkit/lib/styles.css";

import { useContractKit } from "@celo-tools/use-contractkit";
import { useCallback, useMemo } from "react";
import { AddressLink } from "@matintosh/masa-ui";

export function CeloConnector() {
  const { connect, address } = useContractKit();

  const handleConnect = useCallback(async () => {
    try {
      await connect();
    } catch (e) {
      console.error(e);
    }
  }, [connect]);

  const payableAddress = useMemo(() => {
    if (!address) return address;

    const shortAddress =
      address?.substring(0, 2) +
      "..." +
      address?.substring(address?.length - 5);

    return shortAddress;
  }, [address]);

  return (
    <AddressLink onClick={handleConnect}>
      {payableAddress ?? "Connect your wallet"}
    </AddressLink>
  );
}
