import { useNavigate } from "react-router-dom";
import { Button } from "@matintosh/masa-ui";
import { MasaStats } from "../../components/masa-stats";

import "./styles.css";

export function Dashboard() {
  const navigate = useNavigate();
  return (
    <div className='dashboard-container'>
      <div className='dashboard-welcome'>
        <h1>Welcome</h1>
        <p>
          Thank you for becoming a Masa node operator. This is your gateway to
          the Masa protocol where you are able to access a range of services on
          the network. In order to perform different actions you will need to
          stake tokens to get the appropriate permissions.
        </p>
        <p>
          The protocol is currently in <b>Alpha</b> and you can only use testnet
          tokens (tCORN) to stake. You can claim tokens from the testnet facuet
          <b> here.</b>
        </p>

        <div className='fast-access-container'>
          <div className='fast-access'>
            <Button
              className='fast-access-cta'
              onClick={() => navigate("/stake")}
            >
              + Stake Tokens
            </Button>
            <p>
              Stake tCORN tokens to send private transactions, validate data,
              and access our developer API.
            </p>
          </div>
          <div className='fast-access'>
            <Button className='fast-access-cta'>+ Developer Settings</Button>
            <p>
              Access API keys, and developer setttings so you can start building
              on the Masa Protocol.
            </p>
          </div>
        </div>
      </div>

      <MasaStats />
    </div>
  );
}
