import CrossIcon from './close-icon.png'
import { useNavigate } from "react-router-dom";

import { Card } from "@matintosh/masa-ui";

export interface LoanApplicationModalProps {
    title: string;
    description: string;
    modalIsOpen: boolean;
    setUpModal: (test: boolean) => void;
  }

export function LoanApplicationModal({ title, description, modalIsOpen, setUpModal }: LoanApplicationModalProps) {
    const navigate = useNavigate();

    return (
        <div className={!modalIsOpen ? 'hidden' : 'absolute left-0 top-0 w-screen h-screen flex flex-row justify-center items-center'}>
            <Card className="w-1/4">
                <div className="border-b border-solid border-gray border-opacity-10 flex flex-row content-between">
                    <h3>{title}</h3>
                    <button onClick={()=>{setUpModal(false)}}>
                        <img 
                            src={CrossIcon}
                            className="h-6 w-6"
                        />
                    </button>
                </div>
                <p className="mb-4 mt-4 not-italic text-xs my-6">{description}</p>
                <div className="bg-gray-200 h-100 -my-6 flex flex-row justify-end items-center">
                    <button 
                        onClick={()=>{navigate("/loan-applications")}} 
                        className="bg-white dimensions font-semibold border-2 text-dark-gray mr-8">
                            Review more loans
                    </button>
                </div>
            </Card>
        </div>
        );
}