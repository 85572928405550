import { useContractKit } from "@celo-tools/use-contractkit";
import { useCallback, useEffect, useState } from "react";
import useContracts from "./useContracts";
import unit from "ethjs-unit";

export function useCorn() {
  const { address, connect } = useContractKit();
  const { corn } = useContracts();

  const [cornBalance, setCornBalance] = useState(0);

  const getCornBalance = useCallback(async () => {
    if (!address) return connect();
    const cBalance = await corn.methods.balanceOf(address).call();
    setCornBalance(unit.fromWei(cBalance || 0, "ether"));
  }, [address, setCornBalance, corn, connect]);

  useEffect(() => {
    getCornBalance();
  }, [getCornBalance]);

  return { cornBalance };
}
