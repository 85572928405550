import { Routes, Route } from "react-router-dom";
import { Dashboard } from "../pages/dashboard";
import { Explorer } from "../pages/explorer";
import { ExplorerAddress } from "../pages/explorer-address";
import { LoanApplicationsList } from "../pages/loan-applications";
import { LoanApplication } from "../pages/loan-application";
import { Stake } from "../pages/stake";
import { StakeHistory } from "../pages/stake-history";
export function NodeRouter() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/stake">
        <Route index element={<Stake />} />
        <Route path="history" element={<StakeHistory />} />
      </Route>
      <Route path="/loan-applications">
        <Route index element={<LoanApplicationsList />} />
        <Route path=":id" element={<LoanApplication />} />
      </Route>
      <Route path="/explorer">
        <Route element={<Explorer />} index />
        <Route  path="address/:address" element={<ExplorerAddress />} />
      </Route>
    </Routes>
  );
}
