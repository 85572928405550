import { useContractKit } from "@celo-tools/use-contractkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import useContracts from "./useContracts";
import unit from "ethjs-unit";
import { utils } from "ethers";
import BigNumber from "bignumber.js";

export function useStake() {
  const { address, getConnectedKit, connect } = useContractKit();
  const { stkCorn, corn } = useContracts();

  const [stakedCorn, setStakedCorn] = useState(0);
  const [stakingRewards, setStakingRewards] = useState(0);
  const [stakersCount, setStakersCount] = useState(0);
  const [unpaidBalance, setUnpaidBalance] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [isStaking, setIsStaking] = useState(false);
  const [isStartedUnstaking, setIsStartedUnstaking] = useState(false);

  const [contractAddress, contractShortAddress] = useMemo(() => {
    if (!stkCorn._address) return ["No address", "No address"];

    const shortAddress =
      stkCorn._address?.substring(0, 2) +
      "..." +
      stkCorn._address?.substring(stkCorn._address?.length - 5);

    return [stkCorn._address, shortAddress];
  }, [stkCorn._address]);

  const handleStake = async (amount: string) => {
    const kkit = await getConnectedKit();
    const amnt = utils.parseUnits(amount, 18);
    const approvalTxRaw = await corn.methods.approve(stkCorn._address, amnt);
    const approvalTx = await kkit.sendTransactionObject(approvalTxRaw, {
      gas: "20000000",
      gasPrice: "1000000000",
    });
    const approvalR = await approvalTx.waitReceipt();
    if (approvalR.status !== true) {
      console.error("Approval failed");
      console.error(approvalR);
    }
    console.log("Approval success");
    const stakeTxRaw = await stkCorn.methods.stake(amnt);
    const stakeTx = await kkit.sendTransactionObject(stakeTxRaw, {
      gas: "20000000",
      gasPrice: "1000000000",
    });
    const stakeR = await stakeTx.waitReceipt();
    if (stakeR.status !== true) {
      console.error("stake failed");
      console.error(stakeR);
    }
    console.log("stake success");
  };

  const handleUnstake = async () => {
    const kkit = await getConnectedKit();
    const unstakeTxRaw = await stkCorn.methods.unstake();
    const unstakeTx = await kkit.sendTransactionObject(unstakeTxRaw, {
      gas: "20000000",
      gasPrice: "1000000000",
    });
    const unstakeR = await unstakeTx.waitReceipt();
    if (unstakeR.status !== true) {
      console.error("unstake failed");
      console.error(unstakeR);
    }
    console.log("unstake success");
  };

  const handleWithdraw = async () => {
    const kkit = await getConnectedKit();
    const withdrawTxRaw = await stkCorn.methods.withdraw();
    const withdrawTx = await kkit.sendTransactionObject(withdrawTxRaw, {
      gas: "20000000",
      gasPrice: "1000000000",
    });
    const withdrawR = await withdrawTx.waitReceipt();
    if (withdrawR.status !== true) {
      console.error("unstake failed");
      console.error(withdrawR);
    }
    console.log("unstake success");
  };

  const getStakedCorn = useCallback(async () => {
    if (!address) return connect();
    const stkCornBalance = await stkCorn.methods
      .stakedBalanceOf(address)
      .call();
    setStakedCorn(unit.fromWei(stkCornBalance || 0, "ether"));
  }, [address, setStakedCorn, stkCorn, connect]);

  const getStakingRewards = useCallback(async () => {
    if (!address) return connect();
    const stkRewards = await stkCorn.methods.stakedBalanceOf(address).call();
    setStakingRewards(unit.fromWei(stkRewards || 0, "ether"));
  }, [address, setStakingRewards, stkCorn, connect]);

  const getStakers = useCallback(async () => {
    if (!address) return connect();
    const stkCount = await stkCorn.methods.getStakingCount().call();
    setStakersCount(stkCount);
  }, [address, setStakersCount, stkCorn, connect]);

  const getTotalStaked = useCallback(async () => {
    if (!address) return connect();
    const stkTotal = await stkCorn.methods.stakedTotal().call();
    setTotalStaked(unit.fromWei(stkTotal || 0, "ether"));
  }, [address, setTotalStaked, stkCorn, connect]);

  const getIsStaking = useCallback(async () => {
    if (!address) return connect();
    const isStakingValue = await stkCorn.methods.isStaking(address).call();
    setIsStaking(isStakingValue);
  }, [address, setIsStaking, stkCorn, connect]);

  const getIsStartedUnstaking = useCallback(async () => {
    if (!address) return connect();
    const isStartedUnstakingValue = await stkCorn.methods
      .isStartedUnstaking(address)
      .call();
    setIsStartedUnstaking(isStartedUnstakingValue);
  }, [address, setIsStartedUnstaking, stkCorn, connect]);

  const getUnpaidBalance = useCallback(async () => {
    if (!address) return connect();
    const unpaidV = await stkCorn.methods.unpaidBalanceOf(address).call();
    setUnpaidBalance(unit.fromWei(unpaidV || 0, "ether").toString().substr(0, 12));

  }, [address, setUnpaidBalance, stkCorn, connect]);

  useEffect(() => {
    getUnpaidBalance();
  }, [getUnpaidBalance]);

  useEffect(() => {
    getIsStartedUnstaking();
  }, [getIsStartedUnstaking]);

  useEffect(() => {
    getIsStaking();
  }, [getIsStaking]);

  useEffect(() => {
    getTotalStaked();
  }, [getTotalStaked]);

  useEffect(() => {
    getStakers();
  }, [getStakers]);

  useEffect(() => {
    getStakedCorn();
  }, [getStakedCorn]);

  useEffect(() => {
    getStakingRewards();
  }, [getStakingRewards]);

  const canStake = useMemo(() => {
    return !!address;
  }, [address]);

  const hasStakedBalance = useMemo(() => {
    if (!address) {
      return false;
    }
    return new BigNumber(stakedCorn).isGreaterThan(0);
  }, [address, stakedCorn]);

  const canWithdraw = useMemo(() => {
    if (!address) {
      return false;
    }
    return (
      hasStakedBalance && isStaking === false && isStartedUnstaking === false
    );
  }, [address, isStaking, isStartedUnstaking, hasStakedBalance]);

  const canUnstake = useMemo(() => {
    if (!address) {
      return false;
    }
    return hasStakedBalance && canWithdraw === false;
  }, [address, hasStakedBalance, canWithdraw]);

  return {
    stakedCorn,
    stakingRewards,
    stakersCount,
    contractAddress,
    contractShortAddress,
    totalStaked,
    unpaidBalance,
    handleStake,
    handleUnstake,
    handleWithdraw,
    isStaking,
    hasStakedBalance,
    canWithdraw,
    isStartedUnstaking,
    canUnstake,
    canStake,
  };
}
