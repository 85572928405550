import React from "react";
import "./App.css";
import { Header, SubMenu } from "@matintosh/masa-ui";
import { NodeRouter } from "./router";
import { Page } from "./components/page";
import { CeloConnector } from "./components/celo-connector";
import { useLocation, useNavigate } from "react-router-dom";


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className='App'>
      <Header name='Masa Node' dropdownOptions={[]} logoSubtitle='Node Operator v0.1' action={<CeloConnector />}/>
      <div className='sub-menu-container'>
        <SubMenu
          options={[
            { children: "Dashboard", selected: location.pathname === "/", onClick: () => navigate('/') },
            { children: "Stake", selected: location.pathname === "/stake", onClick: () => navigate('/stake') },
            { children: "Loan Applications", selected: location.pathname === "/loan-applications", onClick: () => navigate('/loan-applications') },
            { children: "Explorer", selected: location.pathname.includes("/explorer"), onClick: () => navigate('/explorer') },


            { children: "Bridge", selected: false, onClick: () => {} },

            { children: "Developer Settings", selected: false, onClick: () => {} },

            { children: "Governance", selected: false, onClick: () => {} },
          ]}
        />
      </div>
      <Page>
        <NodeRouter />
      </Page>
    </div>
  );
}

export default App;
