import { Card, EtherscanAddress, Table } from "@matintosh/masa-ui";
import "./styles.css";

export function StakeHistory() {
  const dataClasses = "not-italic font-light text-sm leading-3 py-4";
  const enfasisClasses = " text-black m-0";
  return (
    <div className="stake-history-table">
      <Card className="stake-history-table">
        <h1>📜 Stake History</h1>
        <Table
          headers={[
            "Asset",
            "Ticker",
            "Staking contract",
            "Staked tokens",
            "Bonding Period",
            "Rates",
            "Transaction ID",
          ]}
          items={
            <>
              <tr
                className="border-b border-solid border-gray border-opacity-10 group"
                data-test-id={`transaction-item`}
              >
                <td className={`${dataClasses} ${enfasisClasses}`}>
                  <div className="flex flex-row justify-center w-full p-1">
                    🌽
                  </div>
                </td>
                <td className={`${dataClasses} ${enfasisClasses}`}>
                  <div className="flex flex-row justify-center truncate ">
                    tCORN
                  </div>
                </td>
                <td className={`${dataClasses} ${enfasisClasses}`}>
                  <div className="flex flex-row justify-center font-semibold truncate text-linkYellow">
                    <EtherscanAddress fullAddress={"Asd"} address={"asd"} />
                  </div>
                </td>
                <td className={`${dataClasses} ${enfasisClasses}`}>
                  <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                    <p className="m-0 font-normal text-black">{23123} 🌽</p>
                  </div>
                </td>
                <td className={`${dataClasses} ${enfasisClasses}`}>
                  <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                    <p className="m-0 font-normal text-black">{12412}</p>
                  </div>
                </td>
                <td className={`${dataClasses} ${enfasisClasses}`}>
                  <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                    <p>
                      <span className="font-normal text-black">10</span> active
                    </p>
                    <p>
                      <span className="font-normal text-black">4</span> inactive
                    </p>
                  </div>
                </td>
                <td className={`${dataClasses} ${enfasisClasses}`}>
                  <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                    <p>17% APY</p>
                  </div>
                </td>
              </tr>
            </>
          }
        />
      </Card>
    </div>
  );
}
