import { Card, EtherscanAddress, SubMenu, Table } from "@matintosh/masa-ui";
import { Button, Pagination, Transaction } from "@hide-on-bush-x/masa-ui";
import { shortAddress, useExplorer } from "../../hooks/useExplorer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRouteQuery } from "../../hooks/use-route-query";
import { useMemo, useState } from "react";
import useContracts from "../../hooks/useContracts";
import { TestMasa, StkCorn } from "@masa-finance/smart-contracts";

const testMasaAddress = TestMasa.networks["44787"].address;
const stkCornAddress = StkCorn.networks["44787"].address;

export function ExplorerAddress() {
  const { stkCorn, corn } = useContracts();
  const { address } = useParams();
  const [page, setPage] = useState(0);

  const query = useRouteQuery();
  const contract = query.get("contract") ?? "masa";

  const { transactions, celoBalance, cornBalance } = useExplorer({
    address,
    page,
    offset: 10,
  });

  const title = useMemo(() => {
    console.log(address, stkCornAddress);
    if (address?.toLowerCase() === testMasaAddress?.toLowerCase())
      return "Masa Contract";
    if (address?.toLowerCase() === stkCornAddress?.toLowerCase())
      return "Stake Contract";
    return "Masa account";
  }, [address, corn, stkCorn]);
  return (
    <div className="w-full">
      <Card>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h1>
              {title}
              <span className="text-linkYellow ml-2">
                {shortAddress(address as string)}
              </span>
            </h1>
            <p className="text-lg text-black">
              <span className="text-gray4">{celoBalance}</span> CELO
              <br /> <span className="text-gray4">{cornBalance}</span> tCORN
            </p>
          </div>

          <div>
            <Pagination page={page} totalPages={10} onChange={setPage} />
          </div>
        </div>
        {transactions.map((item, idx) => (
          <Transaction position={idx} {...item} />
        ))}
      </Card>
    </div>
  );
}
