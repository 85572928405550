import useContracts from "./useContracts";
import { formatDistance } from "date-fns";
import { TestMasa, StkCorn } from "@masa-finance/smart-contracts";
import { useCallback, useEffect, useState } from "react";
import unit from "ethjs-unit";

const baseURL = "https://alfajores-blockscout.celo-testnet.org/api";

const testMasaAddress = TestMasa.networks["44787"].address;
const stkCornAddress = StkCorn.networks["44787"].address;

const contractAddresses = {
  masa: testMasaAddress,
  stake: stkCornAddress,
};

export interface TransactionTypes {
  blockHash: string;
  blockNumber: string;
  from: string;
  to: string;
  gas: string;
  hash: string;
  input: string;
  timeStamp: string;
}

const methods = [
  ["0x3ccfd60b", "Widthraw"],
  ["0xa694fc3a", "Stake"],
  ["0x095ea7b3", "Approve"],
];

export const shortAddress = (address: string) => {
  const shortAddressStr =
    address?.substring(0, 2) + "..." + address?.substring(address?.length - 5);

  return shortAddressStr;
};

const transformTransaction = (t: TransactionTypes) => {
  let method: string | string[] | undefined = methods.find((m) =>
    t.input.startsWith(m[0])
  );
  if (method) {
    method = method[1];
  } else {
    method = shortAddress(t.input);
  }
  const timeStampDate = new Date(0);
  timeStampDate.setUTCSeconds(+t.timeStamp);

  const newTransaction = {
    from: shortAddress(t.from),
    fromAddress: `/explorer/address/${t.from}`,
    to: shortAddress(t.to),
    contractName: t.to.toLowerCase() === testMasaAddress.toLowerCase() ? "Masa" : "Stake",
    contractAddress: `/explorer/address/${t.to}`,
    blockNumber: t.blockNumber,
    blockHash: shortAddress(t.blockHash),
    blockAddress: `https://alfajores-blockscout.celo-testnet.org/block/${t.blockNumber}/transactions`,
    timeStamp: `${formatDistance(timeStampDate, new Date())} ago`,
    hash: shortAddress(t.hash),
    gas: t.gas,
    method,
  };

  return newTransaction;
};

export interface UseExplorerProps {
  contract?: "stake" | "masa";
  address?: string;
  page?: number;
  offset?: number;
}

export function useExplorer({
  contract = "masa",
  address,
  page,
  offset,
}: UseExplorerProps) {
  const { corn } = useContracts();

  const [transactions, setTransactions] = useState([]);
  const [celoBalance, setCeloBalance] = useState(undefined);
  const [cornBalance, setCornBalance] = useState(undefined);

  const fetchCornBalance = useCallback(
    async (address: string) => {
      const cBalance = await corn.methods.balanceOf(address).call();
      let cornAmount = unit.fromWei(cBalance || 0, "ether");
      cornAmount = parseFloat(cornAmount).toFixed(4);
      setCornBalance(cornAmount);
    },
    [address, setCornBalance, corn]
  );

  const fetchTransactions = useCallback(
    async (address: string) => {
      let query = `?module=account&action=txlist&address=${address}`;

      if ((page ?? 0) >= 0 && offset) {
        query += `&page=${page}&offset=${offset}`;
      }

      const res = await fetch(`${baseURL}${query}`);
      const transactions = await res.json();

      const formattedTransactions =
        transactions.result.map(transformTransaction);
      setTransactions(formattedTransactions);
    },
    [setTransactions, contract, page, offset]
  );

  const fetchCeloBalance = useCallback(
    async (address: string) => {
      const query = `?module=account&action=balance&address=${address}`;
      const res = await fetch(`${baseURL}${query}`);
      const balances = await res.json();

      let celoB = unit.fromWei(balances.result || 0, "ether");
      celoB = parseFloat(celoB).toFixed(4);
      setCeloBalance(celoB);
    },
    [address]
  );

  useEffect(() => {
    setTransactions([]);
    if (address) {
      fetchTransactions(address);
      fetchCeloBalance(address);
      fetchCornBalance(address);
    } else {
      fetchTransactions(contractAddresses[contract]);
    }
  }, [contract, fetchTransactions]);

  return { transactions, celoBalance, cornBalance };
}
