import React from 'react'

import { Button, Input, Table, EtherscanAddress, Card, CryptoIcon } from "@matintosh/masa-ui";
import { LoanApplicationModal } from "./loan-application-modal";

import "./styles.css";

export function LoanApplication() {
  const dataClasses = "not-italic font-light text-sm leading-3 py-4";
  const enfasisClasses = " text-black m-0";

  const [declineIsOpen, setupDeclineIsOpen] = React.useState(false);
  const [approvedIsOpen, setupApprovedIsOpen] = React.useState(false);

  function declineModal(modal: boolean) {
    setupDeclineIsOpen(modal);
  }

  function approvedModal(modal: boolean) {
    setupApprovedIsOpen(modal);
  }

  return (
    <div className='loan-application-container'>
      <Card className="applicant-data">
        <div>
            <h3>Applicant details</h3>
            <p className='my-6 text-lg my-8'>
                Loan amount requested:{' '}
                <span className='text-black'>
                        $2,500.00
                </span>
            </p>
            <p className='text-md'>
                Location:{' '}
                <span className='text-black'>
                    United States of America
                </span>
            </p>
            <p className='text-md'>
                Loan term:{' '}
                <span className='text-black'>6 months</span>
            </p>
            <p className='text-md'>
                Interest rate:{' '}
                <span className='text-black'>15% APY</span>
            </p>
            <p className='text-md'>
                Collateral:{' '}
                <span className='text-black'>None required</span>
            </p>
        </div>
        <div className='mt-8'>
            <h3 className='my-8'>Pre-qualification information</h3>
            <p className='text-md'>
                Total net worth:{' '}
                <span className='text-black'>
                    {' '}
                    $50,000.00
                </span>
            </p>
            <p className='text-md'>
                Total cryptocurrency assets:{' '}
                <span className='text-black'>
                    {' '}
                    $4,000.00
                </span>
            </p>
            <p className='text-md'>
                Total assets:{' '}
                <span className='text-black'>
                  $4,000.00
                </span>
            </p>
            <p className='text-md'>
                Total debt:{' '}
                <span className='text-black'>
                    {' '}
                    $4,000.00
                </span>
            </p>
            <p className='text-md'>
                Monthly income:{' '}
                <span className='text-black'>
                    {' '}
                    $15,000.00
                </span>
            </p>
            <p className='text-md'>
                Free cashflow:{' '}
                <span className='text-black'>
                    {' '}
                    $4,500.00
                </span>
            </p>
            <p className='text-md'>
                Debt-to-income ratio:{' '}
                <span className='text-black'>
                    {' '}
                    0.33%
                </span>
            </p>
            <p className='text-md'>
                Masa DeFi credit score:{' '}
                <span className='text-black'>A - 724</span>
            </p>

            <p className='flex flex-row items-center mt-8 text-xl'>
                Recomendation:{' '}
                <h2 className='pl-2 m-0 ml-4 text-success'> Approve</h2>
            </p>
        </div>
      </Card>
      <div className='masa-pool'>
          <Card className=' w-full h-full py-8'>
              <h3>🌽 Masa USDC A Pool</h3>
              <Table
                  headers={[
                      'Asset',
                      'Masa NFT USDC',
                      'Pool Balance',
                      'Pool Utilization',
                  ]}
                  items={
                      <>
                          <tr
                              className='border-b border-gray border-solid border-opacity-10 group'
                              data-test-id={`transaction-item`}>
                              <td
                                  className={`${dataClasses} ${enfasisClasses}`}>
                                  <div className='flex flex-row justify-center w-full p-2'>
                                      <CryptoIcon name='USDC' />
                                  </div>
                              </td>
                              <td
                                  className={`${dataClasses} ${enfasisClasses}`}>
                                  <div className='flex truncate flex-row justify-center'>
                                      <EtherscanAddress
                                          address={'0x7899...67h7'}
                                          fullAddress=''
                                      />
                                  </div>
                              </td>
                              <td
                                  className={`${dataClasses} ${enfasisClasses}`}>
                                  <div className='flex break-words overflow-visible truncate text-left flex-col items-center'>
                                      <p className='text-black font-normal'>
                                          $134,608.00
                                      </p>
                                  </div>
                              </td>
                              <td
                                  className={`${dataClasses} ${enfasisClasses}`}>
                                  <div className='flex break-words overflow-visible truncate text-left flex-col items-center'>
                                      <p>
                                          <span className='text-black font-normal'>
                                              $50,000.00
                                          </span>{' '}
                                          borrowed
                                      </p>
                                      <p>
                                          <span className='text-black font-normal'>
                                              $84,508.00
                                          </span>{' '}
                                          available
                                      </p>
                                  </div>
                              </td>
                          </tr>
                      </>
                  }
              />

              <div className='mt-4'>
                  <div className='flex flex-row w-full  border-b border-gray2 mb-4'>
                      <div className='col-span-1 p-4 flex flex-col'>
                          <h3>Loan details</h3>
                          <div className='flex flex-row w-full'>
                              <p className='text-black text-lg'>
                                Maximum loan amount: $2,500.00
                              </p>
                          </div>
                      </div>
                      <div className='col-span-1 p-4'>
                          <p className='text-md'>
                              Loan term:{' '}
                              <span className='text-black'>
                                  3 months
                              </span>
                          </p>
                          <p className='text-md'>
                              Interest rate:{' '}
                              <span className='text-black'>
                                  10% APY
                              </span>
                          </p>
                          <p className='text-md'>
                              Collateral:{' '}
                              <span className='text-black'>
                                  None required
                              </span>
                          </p>
                      </div>
                  </div>
                  <div className='py-8 mb-4 pb-4 w-70'>
                      <p className='text-md'>Purpose of loan</p>
                      <Input
                          type={'textarea'}
                          placeholder='Write a description of what the loan will be used for in this space. The is no character limit for this information and it is stored to the backend.'
                      />
                  </div>
                  <div className="border-bottom"></div>
                  <div className="w-70">
                      <div className='flex flex-col items-center'>
                          <div className='flex flex-row justify-between font-light w-full mb-4 text-sm '>
                              <p className='text-gray8'>
                                  Approve loan amount
                              </p>
                              <p className='text-gray8'>
                                  Pool balance: $84,608.00 USDC
                              </p>
                          </div>
                          <div className='flex flex-row items-center justify-end w-full relative'>
                              <Input
                                  placeholder='0.00'
                              />
                              <div className='flex flex-row absolute'>
                                  <p className=' mb-0 mr-4'>USDC</p>
                                  <p className='text-black mb-0 mr-4'>
                                      Max
                                  </p>
                              </div>
                          </div>
                          <div className='flex flex-row justify-between w-full gap-x-4'>
                              <Button
                                  className='w-1/2 py-4 mt-4'
                                  onClick={() => {
                                    setupApprovedIsOpen(true)
                                  }}>
                                  Approve
                              </Button>
                              <Button
                                  className='w-1/2 py-4 mt-4'
                                  onClick={() => {
                                    setupDeclineIsOpen(true)
                                  }}>
                                  Decline
                              </Button>
                          </div>
                      </div>
                  </div>
              </div>
          </Card>
      </div>
      <LoanApplicationModal
        title="Declined"
        description="Thank you for reviewing and declining this loan application. 
        The loan will now be declined and a note sent to the applicant. "
        modalIsOpen={declineIsOpen}
        setUpModal={declineModal}
      />
      <LoanApplicationModal
        title="Approved"
        description="Thank you for reviewing and approving this loan application. 
        The loan will now be approved and sent to the applicant to sign terms and conditions."
        modalIsOpen={approvedIsOpen}
        setUpModal={approvedModal}
      />
    </div>
  );
}
