import { MasaStats } from "../../components/masa-stats";
import {
  Button,
  Card,
  Table,
  Input,
  EtherscanAddress,
} from "@hide-on-bush-x/masa-ui";

import "./styles.css";
import { useCallback, useMemo, useState } from "react";
import { useStake } from "../../hooks/useStake";
import { kFormatter } from "../../helpers/k-formater";
import { useCorn } from "../../hooks/useCorn";

import "./styles.css";

export function Stake() {
  const {
    stakersCount,
    stakedCorn,
    contractAddress,
    contractShortAddress,
    totalStaked,
    handleUnstake,
    handleStake,
    handleWithdraw,
    canWithdraw,
    isStartedUnstaking,
    isStaking,
    unpaidBalance,
  } = useStake();

  const { cornBalance } = useCorn();

  const [cornToStake, setCornToStake] = useState("");

  const updateCornToStake = (value: string) => {
    if (!isNaN(+value)) {
      setCornToStake(value);
    }
  };

  const dataClasses = "not-italic font-light text-sm leading-3 py-4";
  const enfasisClasses = " text-black m-0";

  const unstakeButtonText = useMemo(() => {
    if (canWithdraw) {
      return "Withdraw";
    }

    if (isStartedUnstaking) {
      return "Unstaking in progress, please wait";
    }

    return "Unstake";
  }, [canWithdraw, isStartedUnstaking]);

  const handleUnstakeMethod = useCallback(() => {
    if (canWithdraw) {
      return handleWithdraw();
    }

    if (isStartedUnstaking) {
      return null;
    }

    return handleUnstake();
  }, [canWithdraw, isStartedUnstaking, handleUnstake, handleWithdraw]);

  return (
    <>
      <div className="stake-dashboard">
        <div className="stake-container">
          <div className="stake-title">
            <div className="stake-header">
              <h1>Get started</h1>
              <a href="/stake/history">📜 Stake history</a>
            </div>
            <p className="text-black tiers-description">
              <b>
                <u>
                  By staking on the Masa Protocol you get access in the
                  following tiers:
                </u>
              </b>
              <br /> <b>Underwriter:</b> 2,000,000+ tCORN <b>Developer</b>:
              10,000+ tCORN <b>Community:</b> {"<"} 10,000 tCORN
            </p>
          </div>
          <Card className="w-full h-full py-8 ">
            <h3>Stake to Become a 🌽 Masa Validator</h3>
            <Table
              headers={[
                "Asset",
                "Ticker",
                "Masa Staking contract",
                "Staked tokens",
                "Pool Stakers",
                "Validators",
                "Interest APY",
              ]}
              items={
                <>
                  <tr
                    className="border-b border-solid border-gray border-opacity-10 group"
                    data-test-id={`transaction-item`}
                  >
                    <td className={`${dataClasses} ${enfasisClasses}`}>
                      <div className="flex flex-row justify-center w-full p-1">
                        🌽
                      </div>
                    </td>
                    <td className={`${dataClasses} ${enfasisClasses}`}>
                      <div className="flex flex-row justify-center truncate ">
                        tCORN
                      </div>
                    </td>
                    <td className={`${dataClasses} ${enfasisClasses}`}>
                      <div className="flex flex-row justify-center font-semibold truncate text-linkYellow">
                        <EtherscanAddress
                          fullAddress={contractAddress}
                          address={contractShortAddress}
                        />
                      </div>
                    </td>
                    <td className={`${dataClasses} ${enfasisClasses}`}>
                      <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                        <p className="m-0 font-normal text-black">
                          {kFormatter(totalStaked)} 🌽
                        </p>
                      </div>
                    </td>
                    <td className={`${dataClasses} ${enfasisClasses}`}>
                      <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                        <p className="m-0 font-normal text-black">
                          {kFormatter(stakersCount)}
                        </p>
                      </div>
                    </td>
                    <td className={`${dataClasses} ${enfasisClasses}`}>
                      <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                        <p>
                          <span className="font-normal text-black">10</span>{" "}
                          active
                        </p>
                        <p>
                          <span className="font-normal text-black">4</span>{" "}
                          inactive
                        </p>
                      </div>
                    </td>
                    <td className={`${dataClasses} ${enfasisClasses}`}>
                      <div className="flex flex-col items-center overflow-visible text-left break-words truncate">
                        <p>17% APY</p>
                      </div>
                    </td>
                  </tr>
                </>
              }
            />

            <div className="mt-4 border-b border-gray2">
              <h3>Your staked balances</h3>
              <div className="flex flex-row w-full mb-4 border-b border-gray2">
                <p className="w-1/2 text-black">
                  tCORN Balance: {kFormatter(stakedCorn)}
                </p>
                <p className="w-1/2 text-black"></p>
              </div>

              <div className="stake-inputs-container">
                <div className="flex flex-col items-center">
                  <div className="flex flex-row justify-between w-full mb-4 text-sm font-light ">
                    <p className="text-gray8">Deposit CORN</p>
                    <p className="text-gray8">
                      Wallet balance: {kFormatter(cornBalance)} tCORN
                    </p>
                  </div>
                  <div className="relative flex flex-row items-center w-full">
                    <Input
                      placeholder="Enter amount"
                      value={cornToStake}
                      onChange={(e: any) => updateCornToStake(e.target.value)}
                    />
                    <div className="input-helpers">
                      <p className="mb-0 ">CORN</p>
                      <p
                        className="mb-0 text-black cursor-pointer"
                        onClick={() => setCornToStake(cornBalance.toString())}
                      >
                        Max
                      </p>
                    </div>
                  </div>
                  <Button
                    disabled={false}
                    className="w-full py-4 mt-4"
                    onClick={() => {
                      handleStake(cornToStake);
                    }}
                  >
                    {isStaking ? "Stake more" : "Stake"}
                  </Button>
                </div>
                <div className="flex flex-col items-center">
                  <div className="flex flex-row justify-between w-full mb-4 text-sm font-light ">
                    <p className="text-gray8">Withdraw CORN</p>
                    <p className="text-gray8">
                      Earned rewards: {unpaidBalance} CORN
                    </p>
                  </div>
                  <Button
                    className="w-full py-4 mt-4"
                    onClick={handleUnstakeMethod}
                  >
                    {unstakeButtonText}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <MasaStats />
      </div>
    </>
  );
}
