import "./styles.css";
import qs from 'query-string'
import { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router'

import { Table, CryptoIcon } from "@hide-on-bush-x/masa-ui";
import { Pagination } from "../../components/pagination/pagination";

export function LoanApplicationsList() {
  const navigate = useNavigate();
  const { search } = useLocation()
  const queryParams = useMemo(() => qs.parse(search), [search])
  const dataClasses = "not-italic font-light text-sm leading-3 py-4";
  const enfasisClasses = " text-black m-0";

  const page = useMemo(() => {
    return parseInt(queryParams['page']?.toString() || '0')
}, [queryParams])

  return (
    <div className="loans-application-container box">
      <div className="flex flex-row content-between">
        <h3>Loan history</h3>
        <Pagination
            page={+page}
            totalPages={10}
            onChange={p =>
                navigate(
                    `/loan-applications?page=${p}`,
                )
            }
        />
      </div>
      <Table
        headers={[
          "Asset",
          "Loan Pool",
          "Amount (Principle)",
          "Amount (Interest)",
          "Total",
          "Term",
          "Interest Rate",
          "Monthly Repayment",
          "Credit Score",
          "Status"
        ]}
        items={
          <tr
            className="border-b border-solid border-gray border-opacity-10 group"
            data-test-id={`transaction-item`}
            onClick={() => navigate(`/loan-applications/id`)}
          >
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center w-full p-1">
                <CryptoIcon name="btc" />
              </div>
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center truncate ">
                Masa USDC A Pool
              </div>
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center font-semibold truncate">
                $2,500.00
              </div>
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center font-semibold truncate">
                $187,50
              </div>
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center font-semibold truncate">
                $2,687.50
              </div> 
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center truncate ">
                6 month
              </div>          
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center font-semibold truncate">
                %15 APY
              </div>
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center font-semibold truncate">
                $447,92
              </div>
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center font-semibold truncate">
                A - 724
              </div>
            </td>
            <td className={`${dataClasses} ${enfasisClasses}`}>
              <div className="flex flex-row justify-center font-semibold truncate">
                Pending 0/10
              </div>
            </td>
          </tr>
        }
      />
    </div>
  );
}
